import React from 'react'
import { useState, useEffect } from 'react';
import {IOneplaceLibraryContextProp, withOneplaceLibraryContext} from 'oneplace-components';
import { Checkbox, FormControlLabel, TextField, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Button } from '@material-ui/core';

export type IncidentSubmissionStatus = null | 'submitting' | 'success' | 'error'

export interface IIncidentSubmissionDialogProps extends IOneplaceLibraryContextProp{
    status: IncidentSubmissionStatus
    content: string | React.ReactElement<any>
    contentDetail?: string | React.ReactElement<any>
    isOpen: boolean
    onClose: () => void
    onConfirm: (emails: string) => void
}

export const IncidentSubmissionDialog = withOneplaceLibraryContext(
    (props: IIncidentSubmissionDialogProps) => {
  
    const [showCancelButton, setShowCancelButton] = useState<boolean>(false)
    const [showConfirmButton, setShowConfirmButton] = useState<boolean>(false)
    const [sendEmail, setSendEmail] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')

    if(props.status === 'submitting' ){
        if(showCancelButton) setShowCancelButton(false)
        if(showConfirmButton) setShowConfirmButton(false)
    } else {
        if(!showCancelButton && props.status !== 'success') setShowCancelButton(true)
        if(!showConfirmButton) setShowConfirmButton(true)
    }

    useEffect( () => {
        if(!sendEmail && email.length > 0){
            setEmail('')
        }
    },[sendEmail, email])

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
        >
            <DialogTitle>Submit {props.ctx.i18next.t('customLabel_incident')}</DialogTitle>
            <DialogContent>
                {props.status === 'submitting' ? 
                    <div style={{textAlign: 'center', width: '100%'}}>
                        <CircularProgress size={30} />
                    </div> : props.content
                }
                {!props.status && 
                    <div>
                        <FormControlLabel 
                            control={<Checkbox color='primary'/>} 
                            checked={sendEmail} 
                            label="Send a copy to my Email"
                            onChange={ (e: any) => {
                                setSendEmail(!sendEmail)
                            }} 
                        />
                        {sendEmail && 
                            <TextField 
                                label='E-mail' 
                                helperText='(Tip: You can enter multiple e-mails separated by comma)'
                                value={email}
                                fullWidth
                                InputLabelProps={{shrink: true}}
                                onChange={(e: any) => setEmail(e.target.value)}
                            />
                        }
                    </div>
                }
            </DialogContent>
            <DialogActions>
                {showCancelButton &&
                    <Button color='primary' onClick={props.onClose}>
                        Cancel
                    </Button>
                }
                {showConfirmButton  &&
                    <Button color='primary' onClick={() => props.onConfirm(email)}>
                        OK
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
})
