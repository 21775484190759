
import { CONFIG } from '../../config';
import { IEmailFields } from '../../models/EmailTypes';
import { IChecklistInfoResponse } from '../../components/checklists/Checklist';
import { UserFacingError } from '../../errors/UserFacingError';
import { IChecklistResponse } from '../../components/checklists/utils/submitChecklist';
import {IAttachment, Api as ApiOnePlaceComponents, IApiClientConfig} from 'oneplace-components'
import { IIncident, IIncidentSignResponse } from '../../models/Incident';


export type ScheduleType = 'completed' | 'week' | 'overdue' | 'today';

export interface IApi {   
    getAuthenticatedPhotoUrl(url: string): string
    submitChecklist(checklistId: number, checklistData: FormData, timeout: number): Promise<IChecklistResponse>;
    sendChecklistEmail(checklistId: number, message: IEmailFields): Promise<void>;
    fetchChecklist(): Promise<IChecklistInfoResponse>;
    saveAttachment(attachment: IAttachment): Promise<any>
    viewAttachment(attachment: IAttachment): void
    deleteAttachment(attachment: IAttachment): Promise<any>

    fetchIncidentSign(): Promise<IIncidentSignResponse>;
    submitIncidentSign(incident: IIncident): Promise<any>;
}

export class Api implements IApi {

    region: string;
    path: string;
    constructor(region :string, path: string){
        this.region = region;
        this.path = path;
    }

    getAuthenticatedPhotoUrl(url: string): string {        
        return new ApiOnePlaceComponents().getAuthenticatedPhotoUrl(url)
    }

    async submitChecklist(checklistId: number, checklistData: FormData, timeout: number) {
        try {
            const res = await fetch(
                `${this.getBaseUrl()}`,
                {
                    method: 'POST',
                    headers: {
                        'X-Survey-Version': CONFIG.apiVersion
                    },
                    redirect: 'follow',
                    body: checklistData
                }
            );
            if (!res.ok) throw res;
            const response = await res.json()
            if (!response.success && typeof response.data == 'string') {
                throw new UserFacingError(response.data);
            }
            if (!response.data || !response.data.Location) {
                throw new Error('Error submitting Survey.');
            }
            
            const checklistResponse = {
                checklistId : response.data.Location.split('/').slice(-2)[0],
                score: response.data.score,
                unansweredQuestions: response.data.unansweredQuestions
            };
            return checklistResponse as IChecklistResponse;
        } catch (error) {
            console.error('Error:', error);
            try {
                const errResponse = await error.json()
              
                throw new Error('Error submitting Survey. ' + errResponse.data);
            } catch (e) {
                if (e instanceof Error) {
                    throw e
                } else{
                    throw new Error('Error submitting Survey. ' + e);
                }
            }
        }
    }

    async sendChecklistEmail(checklistId: number, message: IEmailFields) {
        try {
            const res = await fetch(
                `${this.getBaseUrl()}/${checklistId}/email`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Survey-Version': CONFIG.apiVersion
                        },
                        redirect: 'follow',
                        body: JSON.stringify({ message })
                    }
            );
            const response = await res.json()
            if (!response.success) {
                throw new UserFacingError(!response.data ? 'Email sending failed. Response: ' + JSON.stringify(response) : response.data);
            }
            return response;
        } catch (e) {
            console.error('Error:', e);
            throw new UserFacingError('Email sendingnpm star failed. Response: ' + JSON.stringify(e));
        }
        
    }

    async fetchChecklist(){
        try {
            const res = await fetch(
                `${this.getBaseUrl()}`, 
                 {  
                     headers: {'X-Survey-Version': CONFIG.apiVersion},
                     redirect: 'follow'
                }
            );         
            if (res.url) {
                // old US/CA clients have old url pointing to the sydney/pacific region.  
                /// We detect if a redirect happens(old US/CA clients) and save the new region to use when we post the checklist
                if(res.url !== this.getBaseUrl()) {
                    const path = res.url.split("survey/")
                    if (path.length  >= 2) {
                        this.region =  path[1].substring(0,2)
                        console.log (this.region)
                    }
                }
            }
            const data = await res.json();            
            return data;
        } catch (err) {
            return { 
                success: false,
                data: "network error",
              
            };
        }
    }  

    async saveAttachment(attachment: IAttachment) {        
        const config :IApiClientConfig = {
            url: `${this.getBaseUrl()}/checklistAttachment`,
            header: {'X-Survey-Version': CONFIG.apiVersion},
            timeout: 300000
        } 
        return new ApiOnePlaceComponents(config).saveAttachment(attachment)
    }

    viewAttachment(attachment: IAttachment){
        if(!attachment.url) return;
        window.open(attachment.url)
    }

    async deleteAttachment(attachment: IAttachment){      
        const config :IApiClientConfig = {
            url: `${this.getBaseUrl()}/checklistAttachment`,
            header: {'X-Survey-Version': CONFIG.apiVersion},
            timeout: 60000
        } 
        return new ApiOnePlaceComponents(config).deleteAttachment(attachment)
    }

    async fetchIncidentSign(){
        try {
            const res = await fetch(
                `${CONFIG.apiUrl}/${this.region}/${this.path}/incident-sign`, 
                 {  
                     redirect: 'follow'
                }
            );  
            if(!res.ok) throw res;
            return await res.json();
        } catch (res) {
            const responseType = res.headers.get('content-type');
            let errResponse;
            if(responseType && 
                responseType.indexOf('application/json') !== -1) {                
                errResponse = await res.json()   
                throw new Error(errResponse.data)
            } else if(responseType && 
                responseType.indexOf('text/plain') !== -1) {
                errResponse = await res.text()   
                throw new Error(errResponse)
            } else {
                throw new Error(res)
            }
        }
    }  

    async submitIncidentSign(incident: IIncident) {
        try {                        
            const res = await fetch(
                `${CONFIG.apiUrl}/${this.region}/${this.path}/incident-sign`, 
                {
                    method: 'PUT',
                    redirect: 'follow',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(incident)
                }
            );          
            if (!res.ok) throw res;
        } catch (response) {
            console.error('Error:', response);
            const responseType = response.headers.get("content-type");
            if(responseType.indexOf("application/json") !== -1){
                const errResponse = await response.json()   
                throw new Error(errResponse.data); 
            } else {
                const errResponse = await response.text()   
                throw new Error(errResponse); 
            }
        }
    }

    getBaseUrl(){
        return  `${CONFIG.apiUrl}/${this.region}/${this.path}`
    }
}
