import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import { withStyles, WithStyles, Theme, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({   
    header: {
        backgroundColor: theme.palette.primary.dark
    }
});

export interface IChecklistHeaderProps 
    extends WithStyles<typeof styles> { 
}

const headerFn : React.FC<IChecklistHeaderProps> = (props) => {
    return (
        <AppBar position="fixed" className={props.classes.header} style={{backgroundColor: '#00184c'}}>
            <Toolbar>            
            </Toolbar>
        </AppBar>
    )    
};

const HomeHeader = withStyles(styles)(headerFn)
export default HomeHeader;