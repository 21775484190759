import { IIDAndName } from '../models/Entity';
import { SelectOptions } from 'oneplace-components';

export function mapIDAndNameOptions(options: IIDAndName[]): SelectOptions {
    const newOptions: SelectOptions = [];
    options.forEach((option) => {
        newOptions.push({value: String(option.id), label: option.name});
    });
    return newOptions;
}

export function isNumber(numberValue: string) {
    return (numberValue != null) && !isNaN(Number(numberValue));
}