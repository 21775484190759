import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-image-lightbox/style.css'
import 'react-html5-camera-photo/build/css/index.css';
import App from './App';
import { BrowserRouter, Route } from 'react-router-dom';


ReactDOM.render(
    <BrowserRouter>
      <Route path="/:region/:path" component={App} />
    </BrowserRouter>,
  document.getElementById('root')
);

