export interface IAppConfig {   
    apiUrl: string,
    autoSaveDelay: number,
    displayDateFormat: string,
    displayDateTimeFormat: string,
    displayTimeFormat: string,
    apiVersion: string,
    assigneeSearchResults: number,
    assigneeSearchDelay: number
}

const CONFIG: IAppConfig = {   
    apiUrl: process.env.REACT_APP_API_URL as string || 'http://localhost:8280/survey',
    autoSaveDelay: 1000,
    displayDateFormat: 'dd/MM/yyyy',
    displayDateTimeFormat: 'dd/MM/yyyy hh:mm:ss a',
    displayTimeFormat: 'hh:mm a',
    apiVersion: '1',
    assigneeSearchResults: 20,
    assigneeSearchDelay: 500
};

export { CONFIG };