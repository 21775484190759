import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { ICustomField } from '../../models/Incident';
import {CustomField} from './CustomField'

export interface ICustomFieldsGroupProps {
    fields: ICustomField[];
    onFieldChanged(field: ICustomField): void;
}

export const CustomFieldsGroup = (props: ICustomFieldsGroupProps) => {

    const fields = props.fields;

    if (fields.length === 0) {
        return null;
    }
    return (
        <Grid container spacing={2}>
            {fields.map((field) => (
                <CustomField
                    key={field.customFieldId}
                    field={field}
                    onFieldChanged={props.onFieldChanged}
                />
            ))}
        </Grid>
    );
};