import { 
    IChecklist, 
    dateUtils, 
    AssigneeType, 
    IFranchisee, 
    ISite, 
    ITag} 
from 'oneplace-components';

// Initialise checklist data structure
export function initNewChecklistData(
        checklist: IChecklist,
        assignee: {
            assigneeType: AssigneeType,
            franchisee?: IFranchisee,
            site?: ISite
        },
        tags: ITag[] = [],
        date: string | null = null
    ): void {

    // delete cache_timestamp if present
    delete (checklist as any).__cache_timestamp;
    // Set default checklist values
    if (date) {
        checklist.date = date;
        // from dashboard page, we need to set "hasEvent" flag to true
        checklist.hasEvent = true;
    }
    else {
        checklist.date = dateUtils.formatDate('iso_dateonly', new Date());
    }
    // Apply mandatory tags
    if (tags && tags.length) {
        if (!checklist.tags) {
            checklist.tags = [];
        }
        tags.forEach((tag) => {
            if (tag.mandatory) {
                checklist.tags!.push(tag);
            }
        });
    }
    // Set checklist assignee
    updateChecklistAssignee(checklist, assignee);
}

// Change assignee in checklist data structure
export function updateChecklistAssignee(checklist: IChecklist, params: {
    assigneeType: AssigneeType,
    franchisee?: IFranchisee,
    site?: ISite
}): void {
    if (params.assigneeType === 'site') {
        checklist.site = {
            id: params.site!.id,
            name: params.site!.name
        };
    }
    else {
        checklist.site = null;
        checklist.franchisee = {
            id: params.franchisee!.id,
            name: params.franchisee!.name
        };
    }
}
