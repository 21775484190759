import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface HomeDialogProps{
    title: string;
    message: string;
    isOpen: boolean;
    onConfirm: () => void;
}
export default function HomeDialog(props :HomeDialogProps) {
  
  return (
    <>      
        <Dialog
            open={props.isOpen}
            onClose={props.onConfirm}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>                
                <Button onClick={() => {props.onConfirm()}} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    </>
  );
}
