
import * as React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import SiteIcon from '@material-ui/icons/Place';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {Api} from './../../data_sources/api';
import {IChecklistInfoResponse} from '../checklists/Checklist'
import { 
    PageLoader, IOneplaceLibraryContextProp, 
    AssigneeType, withOneplaceLibraryContext,
    IFranchisee, ISite 
} from 'oneplace-components';
import { RouteComponentProps, withRouter, 
    Redirect, RedirectProps } from 'react-router-dom';
import Input from '@material-ui/core/Input';
import { debounce } from 'debounce';
import { CONFIG } from '../../config';
import Typography from '@material-ui/core/Typography';
import VisibilitySensor from 'react-visibility-sensor';
import HomeHeader from './HomeHeader'
import HomeDialog from './HomeDialog'

const styles = (theme: Theme) => createStyles({
    root: {
    },
    appWrapper: {
        marginTop: 64,
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 800,
        [theme.breakpoints.down('xs')]: {
            marginTop: 52,
        },
    },
    searchCard: {
        marginBottom: 20,
        overflow: 'visible'
    },
    cardContent: {
        paddingTop: 0
    },
    listItemTextRoot: {
        padding: '0 16px'
    },
    errorMessage:{
        position: 'absolute', 
        top:'50%', 
        left:'50%', 
        msTransform: 'translate(-50%, -50%)', 
        transform: 'translate(-50%, -50%)',
        color: 'red'
    }
});

export interface IHomeProps extends
        WithStyles<typeof styles>,
        IOneplaceLibraryContextProp,
        RouteComponentProps<any> {
}

export interface IHomeState {
    loadState: 'loading' | 'loaded';
    region: string;
    encodedChecklistInfo: string;
    checklistInfoResponse: IChecklistInfoResponse | null; 
    assigneeType: AssigneeType | null;
    allAssignees: (IFranchisee| ISite)[] | null;
    filteredAssignees: (IFranchisee | ISite)[] | null;    
    numberShown: number;
    lastSearch: string;
    search: string;
    loadingMore: boolean;
    redirectToChecklist: RedirectProps | null;
    errorMessage: string | null | undefined;
    showDialog: boolean;
}

export const Home = withStyles(styles)(withRouter(withOneplaceLibraryContext(
    class extends React.Component<IHomeProps, IHomeState> {
       
        searchDebounced: () => void = null as any;

        constructor(props: any) {
            super(props);     
            this.state = {
                loadState: 'loading',
                region: this.props.match.params.region,
                encodedChecklistInfo: this.props.match.params.path,
                checklistInfoResponse: null,
                assigneeType: null,
                allAssignees: [],
                filteredAssignees: [],
                numberShown: CONFIG.assigneeSearchResults,               
                lastSearch: '',
                search: '',
                loadingMore: false,
                redirectToChecklist: null,
                errorMessage: null,
                showDialog: true
            };            
            this.searchDebounced = debounce(this.search, CONFIG.assigneeSearchDelay);
        }

        componentDidMount(){
            if(document.title === ''){
                document.title = 'Checklist Survey'
            }            
            if(this.state.region && this.state.encodedChecklistInfo){
                this.loadData();
            }            
        }

        async loadData() {
            const apiClient = new Api(this.state.region, this.state.encodedChecklistInfo);
            const checklistInfoResponse = await apiClient.fetchChecklist();         
            
            if(checklistInfoResponse.success != null && !checklistInfoResponse.success){               
                this.setState({errorMessage: checklistInfoResponse.data, loadState: 'loaded'})
            } 
            else {                
                this.updateMessages(checklistInfoResponse.messages);

                const assigneeType: AssigneeType = checklistInfoResponse.checklist.site!.id 
                    || (checklistInfoResponse.assignments.sites != null 
                        && checklistInfoResponse.assignments.sites.length > 0)? 'site' : 'franchisee';
                
                let entities = assigneeType === 'site' ? 
                checklistInfoResponse.assignments.sites : checklistInfoResponse.assignments.franchisees;            
                // The survey code could be for the wrong region so we have to reset it
                this.setState({
                    region: apiClient.region,
                    checklistInfoResponse,
                    assigneeType,
                    allAssignees: entities,
                    filteredAssignees: entities,
                    lastSearch: '',
                    search: '',
                    loadState: 'loaded'
                });
            }            
        }

        onShowMore = async (showMore: boolean) => {
            if (showMore && !this.state.loadingMore
                    && this.state.numberShown < this.state.filteredAssignees!.length) {
                this.setState({
                    loadingMore: true
                });
                setTimeout(() => {
                    this.setState((state) => ({
                        numberShown: state.numberShown + CONFIG.assigneeSearchResults,
                        loadingMore: false
                    }));
                }, 200);
            }
        }

        onSearchChanged = (event: any) => {
            const search = event.target.value;
            this.setState({ search });
            this.searchDebounced();
        }

        search = () => {
            this.setState((state) => ({
                lastSearch: state.search,               
                filteredAssignees: state.allAssignees!.filter(
                    (assignee: IFranchisee | ISite) =>
                    assignee.name.toLowerCase().includes(state.search.trim().toLowerCase())
                ),
                numberShown: CONFIG.assigneeSearchResults
            }));
        }

        goToChecklist = (assigneeId: number) => {       
            this.setState({
                redirectToChecklist: {
                    to: {
                        pathname: 
                            `/${this.state.region}/${this.state.encodedChecklistInfo}/checklist`, 
                        state:{
                            'region': this.state.region,
                            'encodedChecklistInfo': this.state.encodedChecklistInfo,
                            'checklistInfoResponse': this.state.checklistInfoResponse, 
                            'selectedAssigneeId': assigneeId,
                            'assigneeType': this.state.assigneeType
                        }
                    }
                }
            })
        }

        updateMessages(loadedMessages: string) {               
            const REMOVE_PREFIX = 'com.oneplaceonline.business.customfields.entity.customLabel.';
            const REPLACE_PREFIX = 'customLabel_';
            const messages: { [key: string]: string } = {};            
            for (let [key, value] of Object.entries(loadedMessages)) {
                if(key.includes(REMOVE_PREFIX)){
                    messages[key.split(REMOVE_PREFIX).join(REPLACE_PREFIX)] = value;
                } else{
                    // date formates
                    messages[key] = value;
                }              
            };     
            //checking to avoid error with mocked i18next
            if(this.props.ctx.i18next.addResourceBundle){
                this.props.ctx.i18next.addResourceBundle( 
                    this.props.ctx.i18next.language,'common', messages)
            }            
        }

        confirmDialog = () => {
            this.setState({showDialog: false})
            if(this.state.allAssignees!.length === 1){
                this.goToChecklist(this.state.allAssignees![0].id)
            }
        }

        render() {
            const t = (key: string)=>{return this.props.ctx.i18next.t(key)};

            if(this.state.redirectToChecklist){
                return <Redirect {...this.state.redirectToChecklist} />
            } 
            else if(this.state.errorMessage){
                return (
                    <div style={{textAlign: 'center'}}>
                        <div className={this.props.classes.errorMessage}>
                            <Typography variant="h3">
                                {this.state.errorMessage}
                            </Typography>                    
                        </div>            
                    </div>
                )
            }
            else if(this.state.loadState === 'loaded' && this.state.showDialog){
                const survey = this.state.checklistInfoResponse?.survey;
                return <HomeDialog 
                    title={survey!.name} 
                    message={survey!.message} 
                    isOpen={this.state.showDialog} 
                    onConfirm={()=>{this.confirmDialog()}}/>
            }
            else if (this.state.loadState === 'loaded' && !this.state.errorMessage && this.state.allAssignees!.length > 1) {
                
                const entities = this.state.filteredAssignees!.slice(0, this.state.numberShown);

                return (
                    <>
                        <HomeHeader />
                        <div className={this.props.classes.appWrapper}>
                            <Card className={this.props.classes.searchCard}>
                                <CardHeader
                                    title={ this.state.assigneeType === 'site' ? 
                                        t('customLabel_sites') : t('customLabel_franchisees')}
                                    style={{ paddingBottom: 8 }}
                                />
                                <CardContent className={this.props.classes.cardContent}>
                                    <Input
                                        fullWidth
                                        id="search"
                                        value={this.state.search}
                                        onChange={this.onSearchChanged}
                                        type="search"
                                        placeholder={t('search')}
                                    />
                                </CardContent>
                            </Card>
                            {entities.length > 0 &&
                                <Card>
                                    <CardContent style={{ padding: 16 }}>
                                        <List disablePadding={true}>
                                            {entities.map((assignee: IFranchisee | ISite) => (
                                                <ListItem button style={{ padding: '8px'}} key={assignee.id}
                                                    onClick={() =>{this.goToChecklist(assignee.id)}}>
                                                    <Avatar>
                                                        <SiteIcon />
                                                    </Avatar>
                                                    <ListItemText classes={{ root: this.props.classes.listItemTextRoot }}
                                                        primary={assignee.name}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </CardContent>
                                    <VisibilitySensor onChange={this.onShowMore}  partialVisibility={true}>
                                        <div style={{ minHeight: 8 }}>
                                            {this.state.loadingMore && <PageLoader loading={true} paddingTop={0} />}
                                        </div>
                                    </VisibilitySensor>
                                </Card>
                            }
                            {entities.length === 0 &&
                                <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                                    No matches for "{this.state.lastSearch}"
                                </Typography>
                            }
                        </div>
                    </>
                );
            }           
            else if (this.state.loadState === 'loading'){
                return (
                    <PageLoader loading={true} />
                );
            }            
            else{
                return (
                    <div>
                        <PageLoader loading={false} status={t('data_load_error')} />
                    </div>
                );
            }
        }
    }
)));