import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {i18n} from "./i18n";
import CssBaseline from '@material-ui/core/CssBaseline';
import {Switch, Route, RouteComponentProps, withRouter} from "react-router-dom";
import {Checklist} from './components/checklists/Checklist';
import {Home} from './components/home/Home';
import {OneplaceLibraryContextProvider, Client} from 'oneplace-components';
import {Api} from './data_sources/api/api'
import { CONFIG } from './config';
import IncidentSign from './components/health_safety/IncidentSign';

const theme = createTheme({
    palette: {
        primary: {
            main: '#223d79',
            light: '#5567a8',
            dark: '#00184c',
            contrastText: '#fff',
        },
        secondary: {
            main: '#f50057',
            light: '#ff5983',
            dark: '#bb002f',
            contrastText: '#fff',
        }
    },
    // fix up margin for keyboardDatePicker picker
    overrides: {
        MuiFormControl: {
            root: {
                marginTop: '16px'
            }
        }
    }
});

const settings = {
    addPhotosToGallery: false,
    disableBackgroundSync: true
};



i18n.initialise();

export interface IAppProps extends        
        RouteComponentProps<any> {
}

function App(props :IAppProps)  { 
    const environment: any = {
        platform: 'web',
        os: 'browser',
        target: 'dev'
    };
    const serverUrl = CONFIG.apiUrl.toLowerCase();
    
    if(serverUrl.includes('testsurvey.1placeonline.com'))
        environment.target = 'test'
    else if(serverUrl.includes('survey.1placeonline.com'))
        environment.target = 'prod'
    else
        environment.target = 'dev'

    const client = new Client({ api: new Api(props.match.params.region, props.match.params.path),  settings: settings});
    return (
        <OneplaceLibraryContextProvider env={environment} i18n={i18n._instance} client={client}>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                    <Switch>
                        <Route path="/:region/:path/incident-sign" component={IncidentSign} />
                        <Route path="/:region/:path/checklist" component={Checklist} />
                        <Route path="/:region/:path" component={Home} />
                    </Switch>
            </MuiThemeProvider>
        </OneplaceLibraryContextProvider>
    );  
}

export default withRouter(App);