import {Theme, createStyles} from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
    root: {           
    },
    appWrapper: {
        marginTop: 64,
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 800,
        [theme.breakpoints.down('xs')]: {
            marginTop: 52,
        },
    },
    tabWarningIcon: {
        width: '20px !important',
        height: '20px !important',
        marginLeft: 6,
        color: theme.palette.secondary.light
    },
    groupHeader: {
        padding: '16px 24px 8px'
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    rightIcon: {
        marginLeft: theme.spacing(12)
    },
    entityCard: {
        marginBottom: 20,
        overflow: 'visible'
    },
    entitySelector: {
        marginTop: 10
    },
    cardContent: {
        paddingTop: 0
    },
    header: {
        background: theme.palette.primary.main,
        color: '#FFF',
        padding: 16,
        paddingBottom: 8,
        display: 'flex',
        justifyContent: 'space-between'
    },

    sectionHeader: {
        display: 'flex',
        //alignItems: 'center',
        flexWrap: 'wrap',
        marginTop: '30px',
        color: '#223d79'
    },
    sectionIconHeaderStyle: {
        color: '#223d79',
        fontSize : '30px'
    },
    sectionTextHeaderStyle: {
        marginLeft: '5px',
        fontSize: '20px',
        color: '#223d79'
    }
});

export default styles;