import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Field } from 'formik';
import Grid, { GridProps } from '@material-ui/core/Grid';
import { IControlProps } from './types';

export interface IFormikFieldProps extends Partial<IControlProps> {
    name: string;
    label: string;
    component: any;
    [propName: string]: any;
    gridProps?: GridProps;
}

// Due to performance problems with Formik on large forms (re-rendering whole form)
// I recommend not using this for new screens (use standard React methods instead)

export const FormikField = (props: IFormikFieldProps) => {
    const { name, label, component, gridProps, ...otherProps } = props;
    const field = (
        <FormControl fullWidth disabled={props.disabled || false}>
            <InputLabel htmlFor={name} shrink={true}>{props.label}</InputLabel>
            <Field id={name} name={name} label={label} component={props.component} {...otherProps} />
        </FormControl>
    );
    if (gridProps) {
        return (
            <Grid item {...gridProps}>
                {field}
            </Grid>
        );
    }
    else {
        return field;
    }
};
