
import * as React from 'react';
import { useState } from 'react'
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { IIncidentPersonFields, IIncident } from '../../models/Incident';
import Grid from '@material-ui/core/Grid';
import {  TextField, Typography } from '@material-ui/core';
import { CustomFieldsGroup } from '../custom_fields/CustomFieldsGroup';
import  styles from './IncidentSignStyles';
import PersonIcon from '@material-ui/icons/Person';

export interface IIncidentPersonFieldsProps extends
    WithStyles<typeof styles>{
    incident: IIncident;
}

export default withStyles(styles)(
    (props: IIncidentPersonFieldsProps) => {

        const [personFields, setPersonfieds] = useState<IIncidentPersonFields | null>()

        // when editing incident that has person fields
        if(!personFields && props.incident.personFields && props.incident.personFields.id){
            setPersonfieds(props.incident.personFields)
        }         

        return (
            personFields ?
            <>
                <div className={props.classes.sectionHeader}>
                    <PersonIcon className={props.classes.sectionIconHeaderStyle}/>
                    <Typography variant='subtitle1' className={props.classes.sectionTextHeaderStyle}>
                        Person
                    </Typography>
                </div>                    
                <Grid container spacing={0} style={{ marginBottom: 0 }}>                        
                    {personFields.firstName != null &&
                        <Grid item xs={12} sm={6}>
                            <TextField label='First Name'
                                value={personFields.firstName}
                                fullWidth disabled
                                InputLabelProps={{shrink: true}}
                            />
                        </Grid>
                    }
                    {personFields.midName != null &&
                        <Grid item xs={12} sm={6}>
                            <TextField label='Mid Name'
                                value={personFields.midName}
                                fullWidth disabled
                                InputLabelProps={{shrink: true}}
                            />
                        </Grid>
                    }
                    {personFields.lastName != null &&
                        <Grid item xs={12} sm={6}>
                            <TextField label='Last Name'
                                value={personFields.lastName}
                                fullWidth disabled
                                InputLabelProps={{shrink: true}}
                            />
                        </Grid>
                    }
                    {personFields.email != null &&
                        <Grid item xs={12} sm={6}>
                            <TextField label='Email'
                                value={personFields.email}
                                fullWidth disabled
                                InputLabelProps={{shrink: true}}
                            />
                        </Grid>
                    }
                    {personFields.workPhoneNumber != null &&
                        <Grid item xs={12} sm={6}>
                            <TextField label='Work Phone Number'
                                value={personFields.workPhoneNumber}
                                fullWidth disabled
                                InputLabelProps={{shrink: true}}
                            />
                        </Grid>
                    }
                    {personFields.homePhoneNumber != null &&
                        <Grid item xs={12} sm={6}>
                            <TextField label='Home Phone Number'
                                value={personFields.homePhoneNumber}
                                fullWidth disabled
                                InputLabelProps={{shrink: true}}
                            />
                        </Grid>
                    }
                    {personFields.mobileNumber != null &&
                        <Grid item xs={12} sm={6}>
                            <TextField label='Mobile Number'
                                value={personFields.mobileNumber}
                                fullWidth disabled
                                InputLabelProps={{shrink: true}}
                            />
                        </Grid>

                    }
                    {personFields.externalId != null &&
                        <Grid item xs={12} sm={6}>
                            <TextField label='External ID'
                                value={personFields.externalId}
                                fullWidth disabled
                                InputLabelProps={{shrink: true}}
                            />
                        </Grid>
                    }
                    {personFields.customFields &&
                        <CustomFieldsGroup
                            key={personFields.personId} // force render when changing person
                            fields={personFields.customFields.map(cf => {
                                cf.readonly = true
                                return cf
                            })}
                            onFieldChanged={() => {}}
                        />
                    }
                </Grid>
            </> : <></>
        )
    }
)