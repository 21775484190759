
import { IChecklist, IPhoto, IPhotoComment, dateUtils } from 'oneplace-components';
import { IApi } from '../../../data_sources/api';
import { utils } from 'oneplace-components';
import { IImageStorage, STORED_IMAGE_PREFIX } from 'oneplace-components';


export type ChecklistPhotoList = Array<{
    fieldId: string,
    groupId: string,
    row?: number,
    col?: number,
    photo: IPhoto
}>;

export interface IChecklistResponse {
    checklistId: number;
    score: number;
    unansweredQuestions: number;
}

export function collateNewChecklistPhotoComments(checklistPhotos: ChecklistPhotoList, checklistData: IChecklist) {
    const newComments: IPhotoComment[] = [];

    checklistPhotos.forEach((photo) => {
        if (photo.photo.comments) {
            photo.photo.comments!.filter((c) => c.id == null && c.photoId != null)
                                .forEach((c1) =>
                                    newComments.push(c1),
                                );
        }
    });
    checklistData.newPhotoComments = newComments;
}

export function collateChecklistPhotos(checklistData: IChecklist) {

    const photoList: ChecklistPhotoList = [];

    checklistData.groups.forEach((group) => {
        group.fields.forEach((field) => {
            if (field.photos) {
                field.photos.forEach((photo) => {
                    photoList.push({
                        fieldId: field.id.toString(),
                        groupId: '',
                        photo
                    });
                });
                field.photos = null; // API does not read photos from here
            }
            if (field.dataGrid && field.dataGrid.values && field.dataGrid.values.length) {
                field.dataGrid.values.forEach((row, rowIdx) => {
                    row.forEach((gridField, colIdx) => {
                        if (gridField.photos) {
                            gridField.photos.forEach((photo) => {
                                photoList.push({
                                    fieldId: field.id.toString(),
                                    groupId: '',
                                    row: rowIdx + 1,
                                    col: colIdx + (field.type === 'fixeddatagrid' ? 2 : 1),
                                    photo
                                });
                            });
                            gridField.photos = null; // API does not read photos from here
                        }
                    });
                });
            }
        });
        if (group.photos) {
            group.photos.forEach((photo) => {
                let photoGroupID  = null;
                if (group.checklistGroupId != null) {
                    photoGroupID = group.checklistGroupId.toString();
                } else {
                    photoGroupID = group.id.toString();
                }
                photoList.push({
                    fieldId: '',
                    groupId: photoGroupID,
                    photo
                });
            });
            group.photos = null; // API does not read photos from here
        }
    });
    return photoList;
}


export async function addChecklistPhotosToFormData(
        imageStorage: IImageStorage, photos: ChecklistPhotoList, data: FormData,
        attachmentField = 'attachment[]', attachmentTemp = 'attachmentTemp[]') {
    // Get photo data
    for (const photo of photos) {
        // Make sure we don't try to upload images already on server 
        console.log('checking photo', photo);      
        if (photo.photo.attachmentType !== 'attachment' 
            && photo.photo.url.startsWith(STORED_IMAGE_PREFIX)) {
            console.log('Sending...');
            const photoData = await imageStorage.getImageData(photo.photo.url);
            const meta = photo.photo;
            let takenDate = meta.photoTakenDate || '';
            if (takenDate) {
                takenDate = dateUtils.formatDate('exif', takenDate);
            }
            data.append(attachmentField, photoData, meta.fileName);
            data.append(meta.fileName, meta.comment || '');
            data.append(meta.fileName + 'photoTakenDate', takenDate);
            data.append(meta.fileName + 'fieldID', photo.fieldId);
            data.append(meta.fileName + 'groupID', photo.groupId);
            if (typeof photo.row != 'undefined' && typeof photo.col != 'undefined') {
                data.append(meta.fileName + 'row', String(photo.row));
                data.append(meta.fileName + 'col', String(photo.col));
            }
        } else if (photo.photo.attachmentType === 'attachment' && photo.photo.uuid){
            data.append(attachmentTemp, photo.photo.uuid); 
            data.append(photo.photo.uuid, photo.photo.comment || '');          
        }
    }
}


export function fixChecklistData(checklistData: IChecklist) {
    // Delete fields that are not accepted by backend
    delete checklistData.editEmail;
    // Set the useAttributes field correctly
    if (checklistData.template) {
        checklistData.useAttributes = true;
    }
    checklistData.groups.forEach((group) => {
        group.fields.forEach((field) => {
            if (field.dateTimeIncludeOffset) {
                delete field.dateTimeIncludeOffset;
            }
            if (field.dateFormat) {
                delete field.dateFormat;
            }
            if (field.dateTimeFormat) {
                delete field.dateTimeFormat;
            }
            if (field.dataGrid && field.dataGrid.values && field.dataGrid.values.length) {
                field.dataGrid.values.forEach((row, rowIdx) => {
                    row.forEach((gridField, colIdx) => {
                        if (gridField.dateTimeIncludeOffset) {
                            delete gridField.dateTimeIncludeOffset;
                        }
                        if (gridField.dateFormat) {
                            delete gridField.dateFormat;
                        }
                        if (gridField.dateTimeFormat) {
                            delete gridField.dateTimeFormat;
                        }
                        if (gridField.conditions){
                            delete gridField.conditions;
                        }
                        if (gridField.conditionalRule){
                            delete gridField.conditionalRule;
                        }
                    });
                });
                field.dataGrid.columnLabels.forEach((columnLabel, i) => {
                    if (columnLabel.field && columnLabel.field.conditions){
                        delete columnLabel.field!.conditions;
                    }
                    if (columnLabel.field && columnLabel.field.conditionalRule){
                        delete columnLabel.field!.conditionalRule;
                    }
                });
            }
            // conditional questions, we don't need to serialize conditional rules
            if (field.conditions){
                delete field.conditions;
            }
            if (field.conditionalRule){
                delete field.conditionalRule;
            }
        });
    });
}


export async function submitChecklist(
    api: IApi,
    franchiseeId: number,
    checklist: IChecklist,
    imageStorage: IImageStorage,
    timeout: number = 300000 // 2018-11-27: changed timeout for checklist to 5Min
) {
    const checklistPostData = new FormData();

    const checklistData = utils.deepCopy(checklist);

    // Collate photos
    const photoList = collateChecklistPhotos(checklistData);

    // Collate new photo comments
    collateNewChecklistPhotoComments(photoList, checklistData);

    // Prepare checklist data for upload
    fixChecklistData(checklistData);

    checklistPostData.append('checklist', JSON.stringify({ checklist: checklistData }));

    await addChecklistPhotosToFormData(imageStorage, photoList, checklistPostData);

    const newChecklistId = await api.submitChecklist(
        checklist.id,
        checklistPostData,
        timeout
    );
 
    return newChecklistId;
}
