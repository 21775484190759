import * as React from 'react';
import { ISelectionLists, SelectOptions, TextControl, CheckboxControl, IHelpInfo,
    ICustomFieldMeta, getControlForField, markdownDataUtils } from 'oneplace-components';
import { Grid, FormControl, InputLabel, Theme, createStyles, withStyles, WithStyles, FormControlLabel } from '@material-ui/core';
import { i18n } from '../../i18n';
import { IIDAndName } from '../../models/Entity';



const styles = (theme: Theme) => createStyles({
    mandatoryField: {
        color: theme.palette.secondary.main
    },
    bgColorBlue: {
        backgroundColor:'#223d79',
    },
    bgColorPink: {
        backgroundColor:'#F50057',
    },
    bgColorPurple: {
        backgroundColor:'#322279',
    },
    whiteLabel: {
        color: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: 11,
    },
});

export interface IFieldProps extends WithStyles<typeof styles> {
    field: ICustomFieldMeta;
    values: {[fieldName: string]: any};
    selectionLists: ISelectionLists;
    onFieldsChanged(values: {[fieldName: string]: any}): void;
    addSpacing?: boolean;
}

export interface IFieldState {
    value: any;
    options: SelectOptions;
    otherShown: boolean;
    otherValue: string;
}

export function fieldIsVisible(field: ICustomFieldMeta, values: any) {
    return !field.visiField || values[field.visiField];
}

export function getFieldHelpInfo(field: ICustomFieldMeta, helpInfos: IHelpInfo[]) {
    return (field.helpInfoLabel && helpInfos.find((info) =>
        info.label === field.helpInfoLabel)) || undefined;
}

export const Field = withStyles(styles)(
    class extends React.Component<IFieldProps, IFieldState> {

    constructor(props: any) {
        super(props);

        const { field } = this.props;
        const apiValue = this.props.values[field.name];
        let options: SelectOptions = [];
        if (this.isSelectField(field)) {
            options = this.props.selectionLists[field.selectionList!];
            // Check if value from API exists in the drop-down
            // and add it if not
            const selectedValue = this.coerceSelectedValueAndLabel(apiValue);
            if (selectedValue && !options.find((option) => option.value === selectedValue.value)) {
                options.push(selectedValue);
            }
        }
        const value = this.apiValueToFieldValue(apiValue);
        this.state = {
            value,
            options,
            otherShown: value && value==='Other',
            otherValue: field.otherField && this.props.values[field.otherField]
        };
    }

    componentDidUpdate(prevProps: IFieldProps) {
        if(this.props.field.name === 'name'){
            if(prevProps.values.name !== this.props.values.name){
                this.setState({value: this.props.values.name})
            }
        }
    }

    isSelectField(field: ICustomFieldMeta) {
        return field.type === 'SELECT' || field.type === 'SELECT:STR';
    }

    coerceSelectedValueAndLabel(value: any): { value: string; label: string} | null {
        // Coerce current select value (could be either String, ID+Name or ID+Label)
        let selectedValue = '';
        let selectedLabel = '';
        if (typeof value == 'string') {
            selectedValue = value;
            selectedLabel = value;
        }
        else if (value && typeof value == 'object') {
            selectedValue = value.id;
            if (value.name)
                selectedLabel = value.name;
            else if (value.label)
                selectedLabel = value.label;
        }
        return (selectedValue && selectedLabel)
            ? {
                value: selectedValue,
                label: selectedLabel
            }
            : null;
    }

    apiValueToFieldValue(apiValue: any) {
        const { field } = this.props;
        if (field.type === 'SELECT') {
            if (field.selectMany) {
                return apiValue
                    ? (apiValue as IIDAndName[]).map((entry) => String(entry.id))
                    : [];
            }
            else {
                return apiValue ? String(apiValue.id) : '';
            }
        }
        else if (field.type === 'SIGNATURE') {
            // We use our SignatureControl, which was created for Checklists
            return apiValue
                ? { value: 'Signed', signatureData: apiValue }
                : { value: '' };
        }
        return apiValue;
    }

    fieldValueToApiValue(fieldValue: any) {
        const { field } = this.props;
        if (field.type === 'SELECT') {
            if (field.selectMany) {
                return fieldValue
                    ? (fieldValue as string[]).map((entry) => ({ id: Number(entry) }))
                    : [];
            }
            else {
                return fieldValue ? { id: Number(fieldValue) } : null;
            }
        }
        else if (field.type === 'SIGNATURE') {
            // Convert SignatureControl value back to api value
            return fieldValue && fieldValue.value
                ? fieldValue.signatureData
                : null;
        }
        return fieldValue;
    }

    setFieldValue = (fieldName: string, newValue: any) => {
        const { name, otherField } = this.props.field;
        if (otherField) {
            if (newValue === 'Other') {
                this.setState({ otherShown: true });
            }
            else {
                this.setState({ otherShown: false });
            }
        }
        this.setState({
            value: newValue
        });
        this.props.onFieldsChanged({ [name]: this.fieldValueToApiValue(newValue) });
    }

    setOtherFieldValue = (fieldName: string, newValue: any) => {
        this.setState({
            otherValue: newValue
        });
        this.props.onFieldsChanged({ [fieldName]: this.fieldValueToApiValue(newValue) });
    }

    findColorClassByHex = (hexCode: string | undefined) =>{
        if (hexCode === undefined) return;
        if('#223d79' === hexCode){
            return this.props.classes.bgColorBlue;
        } else if('#F50057' === hexCode){
            return this.props.classes.bgColorPink;
        } else if('#322279' === hexCode){
            return this.props.classes.bgColorPurple;
        }
    }


    render() {
        const t = i18n.t;
        const { field } = this.props;

        if (!fieldIsVisible(field, this.props.values)) {
            return null;
        }

        const { name, label, otherField, readonly, templateData } = field;
        const isSelectField = this.isSelectField(field);
        const labelClass = field.mandatory
            ? this.props.classes.mandatoryField : '';

        const Component = getControlForField(this.props.field);
        const componentProps: any = {
            field: {
                name,
                value: this.state.value
            },
            form: {
                setFieldValue: this.setFieldValue
            },
            id: `field_${name}`,
            dropdown: isSelectField,
            templateValue: templateData,
            options: this.state.options,
            disabled: readonly,
            markdownData: field.markdownData
        };
        if (typeof field.showBlankOption != 'undefined') {
            componentProps.showBlankOption = field.showBlankOption;
        }
        // field.type check
        if (this.props.field.type === 'SIGNATURE') {
            componentProps.showLabelForNewSignature = true;
        } else if (this.props.field.type === 'DATE') {
            componentProps.dateFormat = field.dateFormat;
        } else if (this.props.field.type === 'DATETIME') {
            componentProps.dateTimeFormat = field.dateTimeFormat;
        } else if (this.props.field.type === 'TIME') {
            componentProps.timeFormat = field.timeFormat;
        } else if (this.props.field.type === 'INFORMATION') {
            componentProps.bgColor = field.bgColor;
        } else if (this.props.field.type === 'TEXT_AREA') {
            componentProps.minRows = 5;
        }

        let otherProps: any = {};
        if (otherField) {
            otherProps = {
                field: {
                    name: otherField,
                    label: label + ': ' + t('other'),
                    value: this.state.otherValue
                },
                form: {
                    setFieldValue: this.setOtherFieldValue
                },
                id: `field_${name}`
            };
        }

        const displayLabel = this.props.field.markdownData ? 
            markdownDataUtils.dangerouslyInsertMarkdownHtml(this.props.field.markdownData) : label

        const bgColorClass = this.findColorClassByHex(this.props.field.bgColor);
        const whiteFontClass = this.props.classes.whiteLabel;
        const coloredSectioning = this.props.field.type === 'INFORMATION' && this.props.field.bgColor;

        const control = (Component === CheckboxControl)
            ? (
                <FormControlLabel
                    control={
                        <Component {...componentProps} />
                    }
                    label={displayLabel}
                />)
            : (
                <FormControl fullWidth disabled={readonly}>
                    <InputLabel htmlFor={name} shrink={true} className={`${labelClass}`}>
                        {displayLabel}                       
                    </InputLabel>
                    {this.props.field.markdownData && <div style={{ marginBottom: 5}}>&nbsp;</div>}
                    {this.props.addSpacing &&
                        <div style={{ marginTop: 25}}>&nbsp;</div>
                    }
                    <Component {...componentProps} />
                </FormControl>
            );

        return (
            coloredSectioning?
            <Grid item xs={12} sm={12} className={`${bgColorClass} ${whiteFontClass}`}>
                {displayLabel}
            </Grid>
            :
            <Grid item xs={12} sm={6}>
                {control}
                {otherField && this.state.otherShown &&
                    <FormControl fullWidth disabled={readonly} style={{ marginTop: 10 }}>
                        <InputLabel htmlFor={otherProps.field.name} shrink={true}>
                            {otherProps.field.label}
                        </InputLabel>
                        <TextControl {...otherProps} />
                    </FormControl>
                }                
            </Grid>
        );
    }
});
