import * as React from 'react';
import { ISelectionLists, ICustomFieldMeta } from 'oneplace-components';
import { mapIDAndNameOptions } from '../../utils';
import {Field} from './Field'
import { ICustomField } from '../../models/Incident';



export interface ICustomFieldProps {
    field: ICustomField;
    onFieldChanged(field: ICustomField): void;
}

export interface ICustomFieldState {
    customFieldId: string;
    field: ICustomField;
    values: {[customFieldId: string]: any};
    fieldMeta: ICustomFieldMeta;
    selectionLists: ISelectionLists;
}

export class CustomField extends React.Component<ICustomFieldProps, ICustomFieldState> {

    constructor(props: any) {
        super(props);
        const { field } = this.props;
        // TODO: These will need to update if the passed field changes
        const selectionLists: ISelectionLists = {};
        if (field.allOptionsList && field.allOptionsList.length) {
            selectionLists.options = mapIDAndNameOptions(field.allOptionsList);
        }
        const customFieldId = String(field.customFieldId);
        const fieldMeta: ICustomFieldMeta = {
            name: customFieldId,
            type: field.type,
            label: field.name,
            mandatory: field.mandatory,
            selectMany: field.selectMany,
            selectionList: 'options',
            templateData: field.templateData,
            readonly: field.readonly || false,
            dateFormat: field.dateFormat,
            dateTimeFormat: field.dateTimeFormat,
            timeFormat: field.timeFormat,
            bgColor: field.bgColor,
            markdownData: field.markdownData
        };

        const values = { [customFieldId]: this.apiValueToFieldValue(field) };
        this.state = {
            customFieldId,
            field,
            fieldMeta,
            values,
            selectionLists
        };
    }

    componentDidUpdate() {
        /* we cannot compare using the prev props as the object is mutated
        on parent component thus the prev and current values are the same */
        if(this.props.field.readonly !== this.state.fieldMeta.readonly){
            this.setState({fieldMeta: {...this.state.fieldMeta, readonly: this.props.field.readonly}})
        }
    }

    apiValueToFieldValue(field: ICustomField) {
        if (this.props.field.type === 'SELECT') {
            if (field.selectMany) {
                return field.selectedOptionsList;
            }
            else {
                return field.selectedOptionsList.length > 0
                    ? field.selectedOptionsList[0]
                    : null;
            }
        }
        else if (this.props.field.type === 'DATE') {
            const dt = field.value;
            if (dt && dt.slice(2, 3) === '-') {
                // API Custom Field Date format is DD-MM-YYYY (does this still happens? I think API formats as ISO8601)
                return `${dt.slice(6, 10)}-${dt.slice(3, 5)}-${dt.slice(0, 2)}`;
            }
            else {
                return dt;
            }
        }
        else if (this.props.field.type === 'BOOLEAN') {
            // could be string or boolean, we check both of them
            return field.value === true || field.value === 'true';
        }
        else if (this.props.field.type === 'PHOTO') {
            return field.imgData;
        }
        return field.value;
    }

    fieldValueToApiValue(fieldValue: any) {
        const updatedField: ICustomField = {...this.props.field};
        if (this.props.field.type === 'SELECT') {
            if (updatedField.selectMany) {
                updatedField.selectedOptionsList = fieldValue;
            }
            else {
                updatedField.selectedOptionsList = fieldValue ? [fieldValue] : [];
            }
        }
        else if (this.props.field.type === 'PHOTO') {
            updatedField.imgData = fieldValue;
        }
        else {
            updatedField.value = fieldValue;
        }
        return updatedField;
    }

    onFieldChanged = (values: {[fieldName: string]: any}) => {
        const { customFieldId } = this.state;
        const value = values[customFieldId];
        const updatedField = this.fieldValueToApiValue(value);

        this.setState({ values });
        this.props.onFieldChanged(updatedField);
    }

    render() {
        const { fieldMeta, values, selectionLists } = this.state;

        return (
            <Field
                key={fieldMeta.name}
                field={fieldMeta}
                selectionLists={selectionLists}
                values={values}
                onFieldsChanged={this.onFieldChanged}
                addSpacing={fieldMeta.type === 'TEXT_AREA' || fieldMeta.type === 'STRING'}
            />
        );
    }
}