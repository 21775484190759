import * as React from 'react';
import { useState, useEffect} from 'react';
import { Card, CardContent, Grid, Typography, withStyles, WithStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import {
    ICustomField, IOneplaceLibraryContextProp, PageLoader, 
    TextAreaControl, TextControl, withOneplaceLibraryContext,
    dateUtils
} 
from 'oneplace-components';
import { Form, Formik } from 'formik';
import { FormikField } from '../form_controls/FormikField';
import { IIncident, IIncidentSignResponse } from '../../models/Incident';
import { LoadStatus } from '../common/types';
import IncidentPersonFields from './IncidentPersonFields';
import { CustomFieldsGroup } from '../custom_fields/CustomFieldsGroup';
import { Api } from '../../data_sources/api';
import  styles from './IncidentSignStyles';
import { IncidentSignHeader } from './IncidentSignHeader';
import ListIcon from '@material-ui/icons/List';
import { CONFIG } from '../../config';
import { IncidentSubmissionDialog, IncidentSubmissionStatus } from './IncidentSubmissionDialog';
import { IncidentFinish } from './IncidentFinish';
import { isNumber } from '../../utils';


interface IIncidentSignProps extends
    RouteComponentProps<any>,
    WithStyles<typeof styles>,
    IOneplaceLibraryContextProp{
}


export default withStyles(styles)(
    withRouter(
        withOneplaceLibraryContext(
            (props: IIncidentSignProps) => {
                
                const t = (key: string) => props.ctx.i18next.t(key)

                const [incident, setIncident] = useState<IIncident | null>(null)
                const [loadStatus, setLoadStatus] = useState<LoadStatus>('loading')  
                const [loadIncident, setLoadIncident] = useState<boolean>(true)
                const [saveIncident, setSaveIncident] = useState<boolean>(false)
                const [showFinalMessage, setShowFinalMessage] = useState<boolean>(false)
                const [loadingErrorMessage, setLoadingErrorMessage] = useState<string>(t('data_load_error'))

                //submit dialog control
                const [submitDialogOpen, setSubmitDialogOpen] = useState<boolean>(false)
                const [submitDialogContent, setSubmitDialogContent] = useState<string | React.ReactElement<any>>('')
                const [submissionStatus, setSubmissionStatus] = useState<IncidentSubmissionStatus>(null)

                

                useEffect(() => {
                    if(document.title === '' 
                        && props.ctx.i18next.exists('customLabel_incident')){
                        document.title = t('customLabel_incident')
                    }                    
                })
                
                //fetch incident
                useEffect(() => {
                    async function getIncident(){
                        try {
                            const region = props.match.params.region
                            const path = props.match.params.path
                            const api = new Api(region, path)
                            const response: IIncidentSignResponse = await api.fetchIncidentSign()
                            
                            //custom messages
                            if(props.ctx.i18next.addResourceBundle){
                                props.ctx.i18next.addResourceBundle( 
                                    props.ctx.i18next.language,'common', response.messages)
                            }
                            
                            if(!response.incident.customFields){
                                response.incident.customFields = []
                            } 
                            
                            //date fields configuration
                            response.incident.customFields.forEach((customField) => {
                                if (customField.type === 'DATE') {
                                    // date format is currently  "dd-MM-yyyy" ie. local date
                                    if (customField.value && customField.value !== '') {
                                        customField.value = dateUtils.convertDDMMYYYYToLocalDate(customField.value);
                                    }
                                    customField.dateFormat = props.ctx.i18next.t('dateFormat');
                                } else if (customField.type === 'DATETIME') {
                                    customField.dateTimeFormat =  props.ctx.i18next.t('dateTimeFormat');
                                } else if (customField.type === 'TIME') {
                                    customField.timeFormat = CONFIG.displayTimeFormat;
                                }            
                            })
                            setIncident(response.incident)                        
                            setLoadStatus('loaded')
                            setLoadIncident(false)
                        } catch(e){
                            setLoadingErrorMessage(e.message)
                            setLoadStatus('load_error')
                        }
                    }

                    if(loadIncident){
                        getIncident()                       
                    }
                }, [loadIncident, props.ctx.i18next, props.match.params])

                //save incident
                useEffect(() => {
                    async function submitIncident(){
                        try {
                            const region = props.match.params.region
                            const path = props.match.params.path
                            const api = new Api(region, path)
                            await api.submitIncidentSign(incident!)
                            setSubmissionStatus('success')   
                            setSubmitDialogContent(`${props.ctx.i18next.t('customLabel_incident')} submitted successfully!`)
                        } catch (e) {
                            setSubmissionStatus('error')
                            setSubmitDialogContent(`${e}`)
                        }                                            
                    }
                    if(saveIncident){
                        setSaveIncident(false)
                        submitIncident()                       
                    }
                }, [incident, saveIncident, props.ctx.i18next, props.match.params])

                function onCustomFieldChanged(updatedField: ICustomField){
                    const fieldIdx = incident!.customFields.findIndex((field) =>
                        field.customFieldId === updatedField.customFieldId);
                    if (fieldIdx === -1) {
                        throw new Error(`Updated custom field id 
                        ${updatedField.customFieldId} did not match any Incident Custom Field!`);
                    }
                    incident!.customFields[fieldIdx] = updatedField;
                }

                function onCloseSubmitDialog(){
                    if(submissionStatus === 'submitting'){
                        return;
                    }                        
                    
                    setSubmitDialogOpen(false)
                    if(submissionStatus === 'success'){
                        setShowFinalMessage(true)
                    } else {                        
                        setSubmitDialogContent('')
                        setSubmissionStatus(null)
                    }                    
                }

                function onConfirmSubmitDialog(emails: string){
                    if(!submissionStatus){
                        setIncident({...incident!, emails})
                        setSaveIncident(true)
                        setSubmissionStatus('submitting')                     
                    } else {
                        onCloseSubmitDialog()
                    }
                }

                function validateCustomFields(): string[] {

                   const errors: string[] = []

                   incident!.customFields
                    .filter(field => field.mandatory)
                    .filter(field => field.type !== 'BOOLEAN')
                    .forEach((field) => {                       
                        const error = 
                            (field.type === 'SELECT' && field.selectedOptionsList.length === 0) ||
                            (field.type === 'PHOTO' && !field.imgData) ||
                            (!['SELECT', 'PHOTO'].includes(field.type) && !field.value)

                        if(error) {
                            errors.push(
                                props.ctx.i18next.t('error_must_enter_value_for', {
                                    field: field.name,
                                    tab: t('details')
                                })
                            );
                        }     
                        
                        if (field.type === 'NUMERIC') {
                            if (!isNumber(field.value)){
                                errors.push(
                                    props.ctx.i18next.t('error_must_enter_number_for', 
                                    {field: field.name}
                                ));
                            }
                        }                           
                    })

                    return errors                       
                }
                
                return (
                    <>
                        {!showFinalMessage && loadStatus === 'loaded' && incident && <>                        
                            <IncidentSignHeader onSubmitIncident={() => {
                                setSubmitDialogOpen(true)

                                const errors = validateCustomFields()
                                if(errors.length > 0) {
                                    setSubmissionStatus('error')
                                    setSubmitDialogContent(
                                        <>
                                            <h4>Validation Error:</h4>
                                            <ul>
                                                {errors.map((error, idx) => (
                                                    <li key={idx}>{error}</li>
                                                ))}
                                            </ul>
                                        </>
                                    )
                                } else{
                                    setSubmissionStatus(null)
                                    setSubmitDialogContent(`Confirm submitting ${t('customLabel_incident')}?`)                                    
                                }
                            }} />                        
                            <div className={props.classes.appWrapper}>                            
                                {/*TITLE */}
                                <div className={props.classes.header}>
                                    <div>
                                        <Typography variant="h5" color="inherit">
                                        {` ${t('customLabel_incident')} ${incident.incidentNo}: ${incident.name}`}
                                        </Typography>
                                        <Typography variant="h6" color="inherit">
                                           {t('customLabel_site')}: {incident.location}
                                        </Typography>
                                    </div>
                                </div>
                                {/*INCIDENT BODY */}
                                <Card className={props.classes.entityCard}>
                                    <CardContent className={props.classes.cardContent}>
                                        <Formik
                                            initialValues={incident}
                                            onSubmit={() => {}}
                                            render={() => (
                                                <Form>
                                                    <Grid container spacing={2} style={{ marginTop: 8 }}>
                                                        <FormikField
                                                            disabled={true}
                                                            name='incidentNo'
                                                            label={`${t('customLabel_incident')} No`}
                                                            gridProps={{ xs: 12, sm: 2 }}
                                                            component={TextControl}
                                                        />
                                                        <FormikField
                                                            disabled={true}
                                                            name='name'
                                                            label='Name'
                                                            gridProps={{ xs: 12, sm: 10 }}
                                                            component={TextControl}
                                                        />
                                                        <FormikField
                                                            disabled={true}
                                                            name='type'
                                                            label='Type'
                                                            gridProps={{ xs: 12, sm: 6 }}
                                                            component={TextControl}
                                                        />
                                                        <FormikField
                                                            disabled={true}
                                                            name='severity'
                                                            label='Severity'
                                                            gridProps={{ xs: 12, sm: 6 }}
                                                            component={TextControl}
                                                        />
                                                        <FormikField
                                                            disabled={true}
                                                            name='description'
                                                            label='Description'
                                                            gridProps={{ xs: 12, sm: 12 }}
                                                            component={TextAreaControl}
                                                        />
                                                    </Grid>
                                                </Form>
                                            )}
                                        />

                                        {incident.personFields && 
                                            <div style={{marginTop: '20px'}}>
                                                <IncidentPersonFields incident={incident} />
                                            </div>
                                        }

                                        {incident.customFields &&
                                            incident.customFields.length > 0 &&
                                                <div style={{marginTop: '20px'}}>
                                                    <div className={props.classes.sectionHeader}>
                                                        <ListIcon className={props.classes.sectionIconHeaderStyle}/>
                                                        <Typography variant='subtitle1' className={props.classes.sectionTextHeaderStyle}>
                                                            Details
                                                        </Typography>
                                                    </div>
                                                    <CustomFieldsGroup
                                                        fields={incident.customFields}
                                                        onFieldChanged={onCustomFieldChanged}
                                                    />
                                                </div>
                                        }
                                    </CardContent>
                                </Card>                        
                            </div>
                        </>}
                        {loadStatus ==='loading' &&
                                <PageLoader loading={true} paddingTop={16} />
                        }
                        {loadStatus === 'load_error' &&
                            <PageLoader loading={false} status={loadingErrorMessage} />
                        }
                        <IncidentSubmissionDialog
                            isOpen={submitDialogOpen}
                            status={submissionStatus}
                            content={submitDialogContent}
                            onClose={() => onCloseSubmitDialog()}
                            onConfirm={onConfirmSubmitDialog}
                        />
                        {showFinalMessage && <IncidentFinish />}
                    </>
                )
            }
        )
    )
)
