import React from 'react';
import Typography from '@material-ui/core/Typography';
import onePlaceLogo from '../img/1Place-logo.png';
import './IncidentFinish.css';
import {IOneplaceLibraryContextProp, withOneplaceLibraryContext} from 'oneplace-components';


interface IIncidentFinishProp 
    extends IOneplaceLibraryContextProp{}

const IncidentFinish = withOneplaceLibraryContext(
    (incidentFinishProp: IIncidentFinishProp) => {
        return (
            <div style={{textAlign: 'center'}}>
                <div id="onePlaceLogo">
                    <div className="txtLogo">
                        <b>Powered by</b>
                    </div>
                    <div>
                        <img className="imgLogo" src={onePlaceLogo} alt="1Place" />
                    </div>
                    <div className="txtLogo">
                        Visit our <a href="https://www.1placechildcare.com">website</a> 
                    </div>                
                </div>            
                <div className="endMessage">                
                    <Typography variant="h3" style={{marginBottom: '15px' }}>
                        {`Thanks for submitting this ${incidentFinishProp.ctx.i18next.t('customLabel_incident')}!`}
                    </Typography>   
                </div>            
            </div>
        )    
});
export {IncidentFinish};
